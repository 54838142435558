export enum DocumentType {
    OTHER,
    CPF,
    RG,
    PASSPORT,
    PROVA_DE_VIDA,
    CNH,
    COMPROVANTE_RESIDENCIA,
    CONTRATO_SOCIAL,
    BILHETE_SEGURO = "bilhete_seguro",
    TERMO_CONTRATACAO_SEGURO = "termo_contratacao_seguro",
}