import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { NumericFormat } from "react-number-format";
import {
  getAllFees,
  saveFeesBatch,
  updateFee,
  deleteFee,
  getAllDeletedFees,
} from "../../../services/banking";
import {
  FeeSearchParams,
  FeesState,
  TransactionTypeFees,
  FeeData,
} from "../../../typings/TransactionFees";
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaSearch,
  FaHistory,
  FaEye,
  FaCalendarAlt,
} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setDefaultLocale } from "react-datepicker";
import { Pagination } from "../../features/Pagination";
import { debounce } from "lodash";

dayjs.extend(customParseFormat);
dayjs.locale("pt-br");
setDefaultLocale("pt-BR");

const transactionLabels: Record<TransactionTypeFees, string> = {
  PIX: "PIX",
  BOLETO: "Boleto",
  TRANSFERENCIA_ENTRE_CONTAS: "Transferência Entre Contas",
  TRANSFERENCIA_OUTRO_BANCO: "Transferência Outro Banco",
};

interface FeeRecord extends FeeData {
  id: number;
  transactionTypeFees: TransactionTypeFees;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  isActive: boolean;
}

type ViewMode = "active" | "deleted";

const feeTypes: Array<keyof FeeData> = ["costFee", "sellInFee", "sellOutFee"];

const feeTypeLabels: Record<keyof FeeData, string> = {
  costFee: "Tarifa de Custo",
  sellInFee: "Tarifa Sell In",
  sellOutFee: "Tarifa Sell Out",
};

const TransactionFeesPage = () => {
  const [feeRecords, setFeeRecords] = useState<FeeRecord[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>("active");

  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [currentFeeId, setCurrentFeeId] = useState<number | null>(null);

  const [fees, setFees] = useState<FeesState>({
    PIX: { costFee: "", sellInFee: "", sellOutFee: "" },
    BOLETO: { costFee: "", sellInFee: "", sellOutFee: "" },
    TRANSFERENCIA_ENTRE_CONTAS: { costFee: "", sellInFee: "", sellOutFee: "" },
    TRANSFERENCIA_OUTRO_BANCO: { costFee: "", sellInFee: "", sellOutFee: "" },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [selectedTransactionType, setSelectedTransactionType] = useState<
    TransactionTypeFees | ""
  >("");
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  const filteredRecords = feeRecords.filter((fee) => {
    const transactionLabel = transactionLabels[fee.transactionTypeFees];
    const searchText = searchTerm.toLowerCase();
    const matchesSearch =
      transactionLabel.toLowerCase().includes(searchText) ||
      fee.costFee.includes(searchText) ||
      fee.sellInFee.includes(searchText) ||
      fee.sellOutFee.includes(searchText) ||
      dayjs(fee.createdAt).format("DD/MM/YYYY").includes(searchText) ||
      dayjs(fee.updatedAt).format("DD/MM/YYYY").includes(searchText) ||
      (fee.deletedAt &&
        dayjs(fee.deletedAt).format("DD/MM/YYYY").includes(searchText));

    let matchesPeriod = true;
    if (startDate || endDate) {
      const feeCreatedAt = dayjs(fee.createdAt);
      const feeDeletedAt = fee.deletedAt ? dayjs(fee.deletedAt) : dayjs();

      if (startDate) {
        matchesPeriod =
          feeCreatedAt.isAfter(dayjs(startDate)) ||
          feeCreatedAt.isSame(dayjs(startDate), "day");
      }

      if (endDate) {
        const endOfDay = dayjs(endDate).endOf("day");
        matchesPeriod =
          matchesPeriod &&
          (feeDeletedAt.isBefore(endOfDay) ||
            feeDeletedAt.isSame(endOfDay, "day"));
      }
    }

    return matchesSearch && matchesPeriod;
  });

  const handleSearchChangeDebounce = debounce((value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  }, 350);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChangeDebounce(e.target.value);
  };

  const fetchFees = async () => {
    try {
      setLoading(true);
      setError(null);

      const paginationParams: FeeSearchParams = {
        page: currentPage,
        limit: resultsPerPage,
      };

      if (searchTerm) {
        paginationParams.search = searchTerm;
      }

      if (startDate) {
        paginationParams.startDate = dayjs(startDate).format("YYYY-MM-DD");
      }

      if (endDate) {
        paginationParams.endDate = dayjs(endDate).format("YYYY-MM-DD");
      }

      const response =
        viewMode === "active"
          ? await getAllFees(paginationParams)
          : await getAllDeletedFees(paginationParams);

      if (response.data) {
        const apiData = response.data;

        const transformedData: FeeRecord[] = apiData.data.map((fee) => ({
          id: fee.id,
          transactionTypeFees: fee.transactionTypeFees,
          costFee: fee.costFee,
          sellInFee: fee.sellInFee,
          sellOutFee: fee.sellOutFee,
          createdAt: fee.createdAt,
          updatedAt: fee.updatedAt,
          deletedAt: fee.deletedAt,
          isActive: fee.isActive,
        }));

        setFeeRecords(transformedData);
        setTotalResults(Number(apiData.total));
        setTotalPages(Number(apiData.totalPages));

        console.log("Dados carregados:", transformedData.length, "registros");

        if (transformedData.length === 0 && Number(apiData.totalPages) > 0) {
          console.log(
            "Redirecionando para a página 1 - não há dados na página atual"
          );
          setCurrentPage(1);
        }
      } else {
        setFeeRecords([]);
        setTotalResults(0);
        setTotalPages(1);
      }
    } catch (err) {
      console.error(`Erro ao carregar tarifas:`, err);
      setError(`Erro ao carregar as tarifas. Por favor, tente novamente.`);
      setFeeRecords([]);
      setTotalResults(0);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  const prevViewModeRef = useRef(viewMode);

  useEffect(() => {
    if (prevViewModeRef.current !== viewMode) {
      setCurrentPage(1);
      setStartDate(null);
      setEndDate(null);
      setSearchTerm("");

      prevViewModeRef.current = viewMode;
    }

    fetchFees();
  }, [currentPage, resultsPerPage, viewMode, searchTerm, startDate, endDate]);

  const handleOpenCreateModal = () => {
    const newFees = {
      PIX: { costFee: "", sellInFee: "", sellOutFee: "" },
      BOLETO: { costFee: "", sellInFee: "", sellOutFee: "" },
      TRANSFERENCIA_ENTRE_CONTAS: {
        costFee: "",
        sellInFee: "",
        sellOutFee: "",
      },
      TRANSFERENCIA_OUTRO_BANCO: { costFee: "", sellInFee: "", sellOutFee: "" },
    };

    setFees(newFees);
    setSelectedTransactionType("");
    setFormErrors({});
    setModalMode("create");
    setShowModal(true);
  };

  const handleOpenEditModal = (fee: FeeRecord) => {
    const newFees = {
      PIX: { costFee: "", sellInFee: "", sellOutFee: "" },
      BOLETO: { costFee: "", sellInFee: "", sellOutFee: "" },
      TRANSFERENCIA_ENTRE_CONTAS: {
        costFee: "",
        sellInFee: "",
        sellOutFee: "",
      },
      TRANSFERENCIA_OUTRO_BANCO: { costFee: "", sellInFee: "", sellOutFee: "" },
    };

    newFees[fee.transactionTypeFees] = {
      costFee: fee.costFee,
      sellInFee: fee.sellInFee,
      sellOutFee: fee.sellOutFee,
    };

    setFees(newFees);
    setFormErrors({});
    setModalMode("edit");
    setCurrentFeeId(fee.id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
    setSuccess(null);
    setFormErrors({});
    setSelectedTransactionType("");
  };

  const handleChange = (
    transactionType: TransactionTypeFees,
    feeType: keyof FeeData,
    value: string
  ) => {
    setFees((prevFees) => ({
      ...prevFees,
      [transactionType]: {
        ...prevFees[transactionType],
        [feeType]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      setSuccess(null);
      setFormErrors({});

      const errors: Record<string, string> = {};

      if (modalMode === "create" && !selectedTransactionType) {
        errors.transactionType = "Selecione um tipo de transação";
      }

      const transactionsToValidate =
        modalMode === "create"
          ? [selectedTransactionType as TransactionTypeFees]
          : (Object.keys(fees).filter((key) => {
              const fee = fees[key as TransactionTypeFees];
              return (
                fee.costFee !== "" ||
                fee.sellInFee !== "" ||
                fee.sellOutFee !== ""
              );
            }) as TransactionTypeFees[]);

      const validatedFees: FeesState = {} as FeesState;

      for (const transactionType of transactionsToValidate) {
        if (!transactionType) continue;

        if (modalMode === "edit") {
          const fee = feeRecords.find((f) => f.id === currentFeeId);
          if (transactionType !== fee?.transactionTypeFees) continue;
        }

        const fee = fees[transactionType];

        for (const feeType of feeTypes) {
          if (!fee[feeType]) {
            errors[`${transactionType}-${feeType}`] = "Campo obrigatório";
          } else if (isNaN(parseFloat(fee[feeType]))) {
            errors[`${transactionType}-${feeType}`] = "Valor inválido";
          }
        }

        if (
          !Object.keys(errors).some((key) =>
            key.startsWith(`${transactionType}-`)
          )
        ) {
          validatedFees[transactionType] = {
            costFee: fee.costFee,
            sellInFee: fee.sellInFee,
            sellOutFee: fee.sellOutFee,
          };
        }
      }

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        setLoading(false);
        return;
      }

      if (modalMode === "create") {
        await saveFeesBatch(validatedFees);
        setSuccess("Tarifa cadastrada com sucesso!");
      } else {
        if (currentFeeId !== null) {
          const fee = feeRecords.find((f) => f.id === currentFeeId);
          const transactionType = fee?.transactionTypeFees;

          if (transactionType && validatedFees[transactionType]) {
            await updateFee(currentFeeId, validatedFees[transactionType]);
            setSuccess("Tarifa atualizada com sucesso!");
          }
        }
      }

      await fetchFees();
      setTimeout(() => {
        setShowModal(false);
        setSuccess(null);
        setError(null);
      }, 500);
    } catch (err) {
      console.error("Erro ao salvar tarifas:", err);
      setError("Erro ao salvar as tarifas. Por favor, tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Tem certeza que deseja excluir esta tarifa?")) {
      try {
        setLoading(true);
        await deleteFee(id);

        await fetchFees();

        setSuccess("Tarifa excluída com sucesso!");
        setTimeout(() => setSuccess(null), 3000);
      } catch (err) {
        console.error("Erro ao excluir tarifa:", err);
        setError("Erro ao excluir a tarifa. Por favor, tente novamente.");
        setTimeout(() => setError(null), 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatCurrency = (value: string) => {
    return `R$ ${value.replace(".", ",")}`;
  };

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchTerm("");
  };

  const handlePageChange = (page: number) => {
    const validPage = Math.max(1, Math.min(page, totalPages));
    setCurrentPage(validPage);
  };

  const handleResultsPerPageChange = (value: number) => {
    setResultsPerPage(value);
    setCurrentPage(1);
  };

  return (
    <div className="container">
      <div className="card my-4 p-4">
        <div className="fee-management">
          <div className="fee-management__header mb-4">
            <h1 className="fee-management__title">Gerenciamento de Tarifas</h1>
            <div className="fee-management__actions-container">
              {viewMode === "active" && (
                <Button
                  variant="primary"
                  onClick={handleOpenCreateModal}
                  disabled={loading}
                  className="fee-management__add-button me-2"
                >
                  <FaPlus className="me-2" /> Nova Tarifa
                </Button>
              )}
              <Button
                variant={
                  viewMode === "active"
                    ? "outline-secondary"
                    : "outline-primary"
                }
                onClick={() =>
                  setViewMode(viewMode === "active" ? "deleted" : "active")
                }
                disabled={loading}
                className="fee-management__view-mode-button"
              >
                {viewMode === "active" ? (
                  <>
                    <FaHistory className="me-2" /> Ver Tarifas Deletadas
                  </>
                ) : (
                  <>
                    <FaEye className="me-2" /> Ver Tarifas Ativas
                  </>
                )}
              </Button>
            </div>
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {success && (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          )}

          {/* Área de pesquisa e filtros */}
          <div className="fee-management__filters mb-4">
            <div className="fee-management__search-container mb-3">
              <div className="fee-management__search-wrapper">
                <input
                  type="text"
                  className="fee-management__search-input"
                  placeholder="Buscar tarifas..."
                  onChange={handleSearchChange}
                  disabled={loading}
                />
                <div className="fee-management__search-icon">
                  <FaSearch />
                </div>
              </div>
            </div>

            {viewMode === "deleted" && (
              <div className="fee-management__period-filter mt-3">
                <div className="fee-management__period-label">
                  <FaCalendarAlt className="me-2" />
                  Filtrar por Período de Vigência:
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  <div className="me-2 mb-2">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      className="fee-management__date-input"
                      placeholderText="Data Inicial"
                      dateFormat="dd/MM/yyyy"
                      locale="pt-BR"
                      isClearable
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>

                  <span className="mx-2 mb-2">até</span>

                  <div className="me-2 mb-2">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate ?? undefined}
                      className="fee-management__date-input"
                      placeholderText="Data Final"
                      dateFormat="dd/MM/yyyy"
                      locale="pt-BR"
                      isClearable
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>

                  {(startDate || endDate || searchTerm) && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={handleClearFilters}
                      className="ms-2 mb-2"
                    >
                      Limpar Filtros
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>

          {loading && !showModal ? (
            <div className="text-center my-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
              </div>
              <p className="mt-2">Carregando tarifas...</p>
            </div>
          ) : (
            <>
              {/* Contagem de registros */}
              <div className="fee-management__record-count mb-2">
                {filteredRecords.length}
                {filteredRecords.length === 1
                  ? " registro encontrado"
                  : " registros encontrados"}
                {(startDate || endDate) && (
                  <span className="ms-2 text-muted">
                    (Filtrado por período)
                  </span>
                )}
              </div>

              {/* Tabela estilizada */}
              <div className="fee-management__table-container">
                {loading ? (
                  <div className="text-center py-4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Carregando...</span>
                    </div>
                    <p className="mt-2">Carregando tarifas...</p>
                  </div>
                ) : (
                  <table className="fee-management__table">
                    <thead>
                      <tr>
                        <th>Tipo de Transação</th>
                        <th>Tarifa de Custo</th>
                        <th>Tarifa Sell In</th>
                        <th>Tarifa Sell Out</th>
                        <th>Data de Criação</th>
                        <th>Última Atualização</th>
                        {viewMode === "deleted" && <th>Data de Exclusão</th>}
                        {viewMode === "active" && <th>Ações</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {feeRecords.length > 0 ? (
                        feeRecords.map((fee) => (
                          <tr key={fee.id}>
                            <td>
                              {transactionLabels[fee.transactionTypeFees] ||
                                fee.transactionTypeFees}
                            </td>
                            <td>{formatCurrency(fee.costFee)}</td>
                            <td>{formatCurrency(fee.sellInFee)}</td>
                            <td>{formatCurrency(fee.sellOutFee)}</td>
                            <td>{dayjs(fee.createdAt).format("DD/MM/YYYY")}</td>
                            <td>{dayjs(fee.updatedAt).format("DD/MM/YYYY")}</td>
                            {viewMode === "deleted" && (
                              <td>
                                {fee.deletedAt
                                  ? dayjs(fee.deletedAt).format("DD/MM/YYYY")
                                  : "-"}
                              </td>
                            )}
                            {viewMode === "active" && (
                              <td className="fee-management__actions">
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => handleOpenEditModal(fee)}
                                >
                                  <FaEdit /> Editar
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => handleDelete(fee.id)}
                                >
                                  <FaTrash /> Excluir
                                </Button>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={viewMode === "deleted" ? 7 : 7}
                            className="text-center py-4"
                          >
                            {searchTerm || startDate || endDate
                              ? "Nenhum resultado encontrado para os filtros aplicados."
                              : viewMode === "active"
                              ? "Nenhuma tarifa ativa cadastrada."
                              : "Nenhuma tarifa deletada encontrada."}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>

              {/* Componente de paginação (deve ser exibido mesmo durante o carregamento) */}
              {totalPages > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  totalResults={totalResults}
                  resultsPerPage={resultsPerPage}
                  onResultsPerPageChange={handleResultsPerPageChange}
                />
              )}
            </>
          )}
        </div>
      </div>

      {/* Modal para criação/edição de tarifas */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === "create" ? "Cadastrar Nova Tarifa" : "Editar Tarifa"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {success && (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          )}

          {modalMode === "create" && (
            <Form.Group className="mb-3">
              <Form.Label>Tipo de Transação</Form.Label>
              <Form.Select
                value={selectedTransactionType || ""}
                onChange={(e) =>
                  setSelectedTransactionType(
                    (e.target.value as TransactionTypeFees) || ""
                  )
                }
                isInvalid={!!formErrors.transactionType}
                disabled={loading}
              >
                <option value="">Selecione o tipo de transação</option>
                {Object.entries(transactionLabels).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </Form.Select>
              {formErrors.transactionType && (
                <Form.Control.Feedback type="invalid">
                  {formErrors.transactionType}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}

          {/* Formulário de Tarifas */}
          <div className="fee-registration__form--inverted mt-3">
            <div className="fee-registration__header">
              <div className="fee-registration__transaction-column">
                Tipo de Transação
              </div>
              {feeTypes.map((feeType) => (
                <div className="fee-registration__fee-column" key={feeType}>
                  {feeTypeLabels[feeType]}
                </div>
              ))}
            </div>

            {modalMode === "edit" ? (
              Object.keys(fees).map((type) => {
                const transactionType = type as TransactionTypeFees;
                const fee = fees[transactionType];

                if (
                  fee.costFee === "" &&
                  fee.sellInFee === "" &&
                  fee.sellOutFee === ""
                ) {
                  return null;
                }

                return (
                  <div className="fee-registration__row" key={transactionType}>
                    <div className="fee-registration__transaction-column">
                      {transactionLabels[transactionType] || transactionType}
                    </div>

                    {feeTypes.map((feeType) => (
                      <div
                        className="fee-registration__fee-column"
                        key={feeType}
                      >
                        <div className="fee-registration__input-wrapper">
                          <span className="fee-registration__currency">R$</span>
                          <NumericFormat
                            value={fee[feeType]}
                            onValueChange={(values) => {
                              handleChange(
                                transactionType,
                                feeType,
                                values.value
                              );
                            }}
                            decimalScale={2}
                            fixedDecimalScale
                            placeholder="0,00"
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix=""
                            disabled={loading}
                            className={`fee-registration__numeric-input ${
                              formErrors[`${transactionType}-${feeType}`]
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formErrors[`${transactionType}-${feeType}`] && (
                            <div className="invalid-feedback">
                              {formErrors[`${transactionType}-${feeType}`]}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })
            ) : selectedTransactionType ? (
              <div className="fee-registration__row">
                <div className="fee-registration__transaction-column">
                  {transactionLabels[selectedTransactionType]}
                </div>

                {feeTypes.map((feeType) => (
                  <div className="fee-registration__fee-column" key={feeType}>
                    <div className="fee-registration__input-wrapper">
                      <span className="fee-registration__currency">R$</span>
                      <NumericFormat
                        value={fees[selectedTransactionType][feeType]}
                        onValueChange={(values) => {
                          handleChange(
                            selectedTransactionType,
                            feeType,
                            values.value
                          );
                        }}
                        decimalScale={2}
                        fixedDecimalScale
                        placeholder="0,00"
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix=""
                        disabled={loading}
                        className={`fee-registration__numeric-input ${
                          formErrors[`${selectedTransactionType}-${feeType}`]
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formErrors[`${selectedTransactionType}-${feeType}`] && (
                        <div className="invalid-feedback">
                          {formErrors[`${selectedTransactionType}-${feeType}`]}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-3 text-muted">
                Selecione um tipo de transação para continuar
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={
              loading || (modalMode === "create" && !selectedTransactionType)
            }
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Processando...
              </>
            ) : modalMode === "create" ? (
              "Cadastrar"
            ) : (
              "Atualizar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransactionFeesPage;