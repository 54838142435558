import { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/features/Navbar";
//import Home from "../components/pages/home";
import Login from "../components/pages/login";
import PasswordResetPage from "../components/pages/passwordReset";
import SchedulesBoletoPage from "../components/pages/schedulesBoleto";
import SchedulesPixPage from "../components/pages/schedulesPix";
import SchedulesTedPage from "../components/pages/schedulesTed";
import SchedulesTransferPage from "../components/pages/schedulesTransfer";
import UserPage from "../components/pages/user";
import UsersPage from "../components/pages/users";
import { AuthContext } from "../context/AuthContext";
import ProposalPage from "../components/pages/proposal";
import InsurancePage from "../components/pages/insurance";
import BoletoPage from "../components/pages/boleto";
import PixPage from "../components/pages/pix";
import TransferPage from "../components/pages/transfer";
import TedPage from "../components/pages/ted";
import TransactionFeesPage from "../components/pages/transactionFees";
import EditUserClientPf from "../components/pages/user/Edit/EditUserClientPf";
import EditUserClientePj from "../components/pages/user/Edit/EditUserClientePj";
import EditUserOperadorPj from "../components/pages/user/Edit/EditUserOperadorPj";
import TotalizersPage from "../components/pages/totalizers";

const RootRoutes = () => {

    const { user } = useContext(AuthContext);

    return (
        <BrowserRouter>
            <Routes>
                <Route key="login" path="/login" element={user ? <Navigate to={"/"} /> : <Login />} />
                <Route key="home" path="/*" element={user ? <PrivateRoutes /> : <Navigate to={"/login"} />} />
            </Routes>
        </BrowserRouter>
    )
}

const PrivateRoutes = () => {
    return (
        <Navbar>
            <Routes>
                <Route key="users" path="/users" element={<UsersPage />} />
                <Route key="clientpf-edit" path="/clientpf-edit" element={<EditUserClientPf />} />
                <Route key="clientpj-edit" path="/clientpj-edit" element={<EditUserClientePj />} />
                <Route key="operadorpj-edit" path="/operadorpj-edit" element={<EditUserOperadorPj />} />
                <Route key="schedules-boleto" path="/schedules-boleto" element={<SchedulesBoletoPage />} />
                <Route key="scheduled-boleto" path="/scheduled-boleto" element={<BoletoPage />} />
                <Route key="schedules-pix" path="/schedules-pix" element={<SchedulesPixPage />} />
                <Route key="scheduled-pix" path="/scheduled-pix" element={<PixPage />} />
                <Route key="schedules-transfer" path="/schedules-transfer" element={<SchedulesTransferPage />} />
                <Route key="scheduled-transfer" path="/scheduled-transfer" element={<TransferPage />} />
                <Route key="schedules-ted" path="/schedules-ted" element={<SchedulesTedPage />} />
                <Route key="scheduled-ted" path="/scheduled-ted" element={<TedPage />} />
                <Route key="insurance-proposals" path="/insurance-proposals" element={<InsurancePage />} />
                <Route key="proposal" path="/proposal" element={<ProposalPage />} />
                <Route key="user" path="/user" element={<UserPage />} />
                <Route key="user" path="/password" element={<PasswordResetPage />} />
                <Route key="home" path="*" element={<Navigate to={"/users"} />} />
                <Route key="transaction-fees" path="/transaction-fees" element={<TransactionFeesPage />} />
                <Route key="totalizers" path="/totalizers" element={<TotalizersPage />} />
            </Routes>
        </Navbar>
    )
}

export default RootRoutes;