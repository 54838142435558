import { Address } from "./Address"; // Atualizado para usar o tipo unificado

export enum ClientePfAprovado {
	WAITING_MANUAL_APPROVAL = 0, // AGUARDANDO VALIDACAO MANUAL
	WAITING_ACCOUNT_OPEN = 1, // AGUARDANDO ABERTURA DE CONTA AUTOMATICA
	ERROR_ACCOUNT_OPEN = 2, // ERROR ABERTURA DE CONTA
	APPROVED = 3, // APROVADO, tudo ok.
	REFUSED = 4, // RECUSADO
}

export type ClientePf = {
	id: number;
	nome: string;
	cpf: string;
	apelido: string;
	dtNasc: string;
	nomeMae: string;
	status: number;
	aprovado: ClientePfAprovado;
	createdAt: string;
	phones?: any;
	addresses?: Address[];
	emails?: any[];
	accounts?: any[];
	oidc_id?: string;
};
