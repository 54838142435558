import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchProposals } from "../../../services/banking";
import { formatCpf } from "../../../utils/format";
import "./styles.scss";

const InsurancePage = () => {
	const navigate = useNavigate();
	const [proposals, setProposals] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);

	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		fetchProposals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const fetchProposals = async () => {
		console.log(`fetchProposals...`);
		setLoading(true);
		try {
			const res = await searchProposals(page, searchText);
			if (res.data) {
				setProposals(res.data);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	return (
		<div className="container-fluid">
			<div className="card my-4 p-4">
				<div className="row mb-4">
					<h1 className="mb-4">Propostas de seguro</h1>
					<div className="d-flex" style={{ gap: "20px" }}>
						<div className="search-container">
							<input
								className="search-input"
								type="text"
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								onKeyDown={(e) => e.key === "Enter" && fetchProposals()}
							/>
						</div>
					</div>
				</div>
				{loading ? (
					<div className="text-center mt-5">Carregando...</div>
				) : (
					<div>
						{proposals && proposals.length > 0 ? (
							<div>
								<table className="table table-striped">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Data de criação</th>
											<th scope="col">CPF</th>
											<th scope="col">Método de pagamento</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										{proposals.map((proposal) => (
											<tr
												className="interactive-table-row"
												key={proposal.id}
												onClick={() => navigate("/proposal?id=" + proposal.id)}
											>
												<th scope="row">{proposal.id}</th>
												<td>
													{dayjs(proposal.created_at).format(
														"DD/MM/YYYY HH:mm"
													)}
												</td>
												<td>{formatCpf(proposal.customer.cpf)}</td>
												<td>{proposal.method_payment}</td>
												<td>
													<button
														type="button"
														className={`btn btn-sm ${
															proposal.status === "completed" ||
															proposal.status === "up_to_date"
																? "btn-success"
																: proposal.status === "not_effective" ||
																  proposal.status === "suspended"
																? "btn-danger"
																: proposal.status === "canceled"
																? "btn-secondary"
																: "btn-warning"
														}`}
													>
														{proposal.status === "completed"
															? "completa"
															: proposal.status === "up_to_date"
															? "em dia"
															: proposal.status === "awaiting_registration"
															? "aguardando registro"
															: proposal.status === "awaiting_payment"
															? "aguardando pagamento"
															: proposal.status === "not_effective"
															? "não efetivada"
															: proposal.status === "overdue"
															? "pagamento em atraso"
															: proposal.status === "suspended"
															? "suspensa"
															: proposal.status === "boleto_refund"
															? "cancelada"
															: proposal.status === "canceled"
															? "cancelada"
															: proposal.status ===
																	"boleto_refund_but_pending_tokio_cancel" ||
															  "canceled_but_pending_tokio_cancel"
															? "cancelamento pendente"
															: ""}
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="text-center mt-5">
								Nenhuma proposta encontrada
							</div>
						)}
						<div className="text-center px-3 pt-3">
							{page > 1 && (
								<button
									className="page-selector"
									onClick={() => setPage(page - 1)}
								>
									{"<"}
								</button>
							)}
							<label className="page-selector">{page}</label>
							{proposals.length > 19 && (
								<button
									className="page-selector"
									onClick={() => setPage(page + 1)}
								>
									{">"}
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default InsurancePage;
