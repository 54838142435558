import api from "./api";

export function getClientePfDocuments(ClietePfId: number) {
    return api.get("/ocr/docs/"+ClietePfId);
}

export const base64toFile = (url: string, filename: string) => {
    const mimeType = url
        .split(`,`)[0]
        .match(/:(.*?);/)
        ?.at(1)
    return fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }))
}

export const uploadDocumentos = async (
    base64: string,
    userId: number,
    documentType: number,
    idempotencyKey: string,
  ) => {
    console.log(base64)

    const img = await base64toFile(base64, `identity`)

    const form = new FormData()
    form.append(`userId`, String(userId))
    form.append(`documentType`, String(documentType))
    form.append(`document`, img)
    console.log(img)

    if (!img.type.includes(`image`)) throw new Error(`Invalid file type`)

    return api.post(`/ocr/valida-documento`, form, {
        headers: { 
            "Content-Type": `multipart/form-data`,
            "x-k4n-tenant": 1,
            "idempotency-key": idempotencyKey,
        },
    })
}

export const uploadDocumentosPj = async (
    base64: string,
    userId: number,
    documentType: number,
    idempotencyKey: string,
  ) => {
    console.log(base64)

    const img = await base64toFile(base64, `identity`)

    const form = new FormData()
    form.append(`userId`, String(userId))
    form.append(`documentType`, String(documentType))
    form.append(`document`, img)
    console.log(img)

    if (!img.type.includes(`image`)) throw new Error(`Invalid file type`)

    return api.post(`/ocr/valida-documento-pj`, form, {
        headers: { 
            "Content-Type": `multipart/form-data`,
            "x-k4n-tenant": `${process.env.REACT_APP_PARTNER_ID}`,
            "idempotency-key": idempotencyKey,
        },
    })
}