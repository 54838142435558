import axios from "axios"

const apiZipCode = axios.create({
  baseURL: `${process.env.REACT_APP_VIA_CEP_URL}`,
})

export class ZipCodeService {
    public static consultZipCode = (zipCode: string) => {
        return apiZipCode.get(`ws/` + zipCode + `/json/`);
    };
}

export default ZipCodeService