import api from "./api";
import { k4nRetryAccountCreationSteps } from "./dto/retry-account-creation.dto";
import { FeeApiResponse, FeeData, FeesState, PaginatedResponse, PaginationParams, TransactionTypeFees } from "../typings/TransactionFees";
import { Address } from "../typings/Address";

export function searchClientePf(page = 1, aprovado: string, pesquisa: string) {
  return api.get("/banking/cliente-pf/search", {
    params: {
      page,
      aprovado: aprovado === "all" ? null : aprovado,
      q: pesquisa || null,
    },
  });
}

export function searchClientePj(page = 1, aprovado: string, pesquisa: string) {
  return api.get("/banking/cliente-pj/search", {
    params: {
      page,
      aprovado: aprovado === "all" ? null : aprovado,
      q: pesquisa || null,
    },
  });
}

export function searchSchedulesBoleto(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-boleto", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getBoleto(id: number) {
  return api.get("/banking/conta/scheduled-boleto", {
    params: {
      id,
    },
  });
}

export function searchSchedulesPix(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-pix", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getPix(id: number) {
  return api.get("/banking/conta/scheduled-pix", {
    params: {
      id,
    },
  });
}

export function searchSchedulesTransfer(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-transfer", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getTransfer(id: number) {
  return api.get("/banking/conta/scheduled-transfer", {
    params: {
      id,
    },
  });
}

export function searchSchedulesTed(page = 1, searchText: string) {
  return api.get("/banking/conta/schedules-ted", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getTed(id: number) {
  return api.get("/banking/conta/scheduled-ted", {
    params: {
      id,
    },
  });
}

export function searchProposals(page = 1, searchText: string) {
  return api.get("/insurance/proposals", {
    params: {
      page,
      q: searchText || null,
    },
  });
}

export function getProposal(id: number) {
  return api.get("/insurance/proposal", {
    params: {
      id,
    },
  });
}

export function getClientePfById(cliente_pf_id: number) {
  return api.get("/banking/cliente-pf/" + cliente_pf_id);
}

export function getClientePjById(cliente_pj_id: number) {
  return api.get("/banking/cliente-pj/find/" + cliente_pj_id);
}

export function getClientePfByCpf(cpf: string) {
  cpf = cpf.replaceAll(".", "").replace("-", "");
  return api.get("/banking/cliente-pf/cpf/" + cpf);
}

export function approveOnBoarding(
  cpf: string,
  aprovado: boolean,
  observacao = ""
) {
  const data = {
    cpf: cpf.replaceAll(".", "").replace("-", ""),
    aprovado: aprovado,
    observacao: observacao || null,
  };
  return api.post("/banking/cliente-pf/aprovar", data);
}

export function approveOnboardingPj(
  cnpj: string,
  aprovado: boolean,
  observacao = ""
) {
  const data = {
    cnpj: cnpj.replaceAll(".", "").replace("-", ""),
    aprovado: aprovado,
    observacao: observacao || null,
  };
  return api.post("/banking/cliente-pj/aprovar", data);
}

export const processAberturaManual = (cpf: string) => {
  return api.get("/banking/cliente-pf/account-open-manual/" + cpf);
};

export const aberturaManualPj = (cnpj: string) => {
  return api.get("/banking/cliente-pj/account-open-manual/" + cnpj);
};

export const getOperatorPeers = (params: { page: number; limit: number, oidc_id: string }) => {
  return api.get(`banking/operator-pj/peers`, { params })
}

export const getOperatorByOidcId = (oidc_id: string) => {
  return api.get(`banking/operator-pj/oidc/` + oidc_id)
}

export const updateAddress = (data: Address, cpf: string) => {
  const address = { address: data }
  return api.post(`/banking/cliente-pf/change-address/${cpf}`, address)
}

export const updateUserAccountOperador = (oidc_id: string, new_data: any) => {
  const data = {
    oidc_id: oidc_id,
    email: new_data.email,
    phone: new_data.phone,
    nome: new_data.nome,
  }
  return api.post(`/banking/operator-pj/atualizar-cadastro`, data)
}


export const updateUserAccountClientPj = (oidc_id: string, new_data: any) => {
  const data = {
    oidc_id: oidc_id,
    email: new_data.email,
    phone: new_data.phone,
    nome: new_data.nome,
  }
  return api.post(`/banking/cliente-pj/atualizar-cadastro`, data)
}

export const updateUserAccount = (oidc_id: string, new_data: any) => {
  const data = {
    oidc_id: oidc_id,
    email: new_data.email,
    phone: new_data.phone,
    nome: new_data.nome,
  }
  return api.post(`/banking/cliente-pf/atualizar-cadastro`, data)
}

export const deleteOperator = (id: number) => {
  return api.delete(`banking/operator-pj/` + id)
}

export const getConsoleUserByOidcId = (oidc_id: string) => {
  return api.get(`banking/console-user/oidc/` + oidc_id);
};

export const sendResetPassEmail = (cpf: string) => {
  return api.get("/banking/cliente-pf/reset-password/" + cpf);
};

export const retryAccountCreationSteps = (
  data: k4nRetryAccountCreationSteps
) => {
  return api.post("/banking/console-user/retry-steps", data);
};

export function formattedCurrency(currency: any) {
  return currency.toLocaleString(`pt-BR`, {
    style: `currency`,
    currency: `BRL`,
    minimumFractionDigits: 2,
  });
}

export function getAllFees(params?: PaginationParams) {
  return api.get<PaginatedResponse<FeeApiResponse>>('/banking/conta/fees', { params });
}

export function getAllDeletedFees(params?: PaginationParams) {
  return api.get<PaginatedResponse<FeeApiResponse>>('/banking/conta/fees/deleted', { params });
}

export function getFeeByTransactionType(type: TransactionTypeFees) {
  return api.get(`/banking/conta/fees/transaction/${type}`);
}

export function saveFeesBatch(fees: FeesState) { 
  const transformedFees: Record<string, any> = {};
  
  for (const transactionType of Object.keys(fees) as TransactionTypeFees[]) {
    transformedFees[transactionType] = {
      costFee: fees[transactionType].costFee || "0",
      sellInFee: fees[transactionType].sellInFee || "0",
      sellOutFee: fees[transactionType].sellOutFee || "0",
    };
  }
  
  return api.post('/banking/conta/fees/batch', { fees: transformedFees });
}

export function updateFee(id: number, feeData: Partial<FeeData>) {
  return api.patch(`/banking/conta/fees/${id}`, feeData);
}

export function deleteFee(id: number) {
  return api.delete(`/banking/conta/fees/${id}`);
}