import { useEffect, useMemo, useState } from "react";
import "./styles.scss";

type TransactionType =
  | "PIX"
  | "BOLETO"
  | "TRANSFERENCIA_ENTRE_CONTAS"
  | "TRANSFERENCIA_OUTRO_BANCO"
  | "TODOS";

interface Transaction {
  id: string;
  type: Exclude<TransactionType, "TODOS">;
  date: string;
  amount: number;
  costFee: number;
  sellInFee: number;
  sellOutFee: number;
}

const transactionLabels: Record<Exclude<TransactionType, "TODOS">, string> = {
  PIX: "PIX",
  BOLETO: "Boleto",
  TRANSFERENCIA_ENTRE_CONTAS: "Transferência Entre Contas",
  TRANSFERENCIA_OUTRO_BANCO: "Transferência Outro Banco",
};

// Mock data
const mockTransactions: Transaction[] = [
  {
    id: "TX001",
    type: "PIX",
    date: "2023-05-10",
    amount: 1500.0,
    costFee: 0.5,
    sellInFee: 1.2,
    sellOutFee: 2.5,
  },
  {
    id: "TX002",
    type: "PIX",
    date: "2023-05-11",
    amount: 750.0,
    costFee: 0.5,
    sellInFee: 1.2,
    sellOutFee: 2.5,
  },
  {
    id: "TX003",
    type: "BOLETO",
    date: "2023-05-09",
    amount: 2300.0,
    costFee: 1.8,
    sellInFee: 2.5,
    sellOutFee: 3.8,
  },
  {
    id: "TX004",
    type: "TRANSFERENCIA_ENTRE_CONTAS",
    date: "2023-05-12",
    amount: 3500.0,
    costFee: 0.0,
    sellInFee: 0.5,
    sellOutFee: 1.0,
  },
  {
    id: "TX005",
    type: "TRANSFERENCIA_OUTRO_BANCO",
    date: "2023-05-08",
    amount: 5000.0,
    costFee: 2.5,
    sellInFee: 4.0,
    sellOutFee: 8.0,
  },
  {
    id: "TX006",
    type: "BOLETO",
    date: "2023-05-13",
    amount: 1200.0,
    costFee: 1.8,
    sellInFee: 2.5,
    sellOutFee: 3.8,
  },
  {
    id: "TX007",
    type: "PIX",
    date: "2023-05-14",
    amount: 850.0,
    costFee: 0.5,
    sellInFee: 1.2,
    sellOutFee: 2.5,
  },
  {
    id: "TX008",
    type: "TRANSFERENCIA_OUTRO_BANCO",
    date: "2023-05-15",
    amount: 4200.0,
    costFee: 2.5,
    sellInFee: 4.0,
    sellOutFee: 8.0,
  },
];

const TotalizersPage = () => {
  const [filter, setFilter] = useState<TransactionType>("TODOS");

  const filteredTransactions = useMemo(() => {
    if (filter === "TODOS") return mockTransactions;
    return mockTransactions.filter((tx) => tx.type === filter);
  }, [filter]);

  const totals = useMemo(() => {
    return filteredTransactions.reduce(
      (acc, tx) => {
        return {
          amount: acc.amount + tx.amount,
          costFee: acc.costFee + tx.costFee,
          sellInFee: acc.sellInFee + tx.sellInFee,
          sellOutFee: acc.sellOutFee + tx.sellOutFee,
          netAmount:
            acc.netAmount +
            (tx.amount - tx.costFee - tx.sellInFee - tx.sellOutFee),
        };
      },
      {
        amount: 0,
        costFee: 0,
        sellInFee: 0,
        sellOutFee: 0,
        netAmount: 0,
      }
    );
  }, [filteredTransactions]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("pt-BR");
  };

  return (
    <div className="container">
      <div className="card my-4 p-4">
        <div className="transaction-summary">
          <h1 className="transaction-summary__title">
            Totalizador de Transações
          </h1>

          <div className="transaction-summary__filters">
            <label htmlFor="transaction-type">Filtrar por tipo:</label>
            <select
              id="transaction-type"
              value={filter}
              onChange={(e) => setFilter(e.target.value as TransactionType)}
              className="transaction-summary__select"
            >
              <option value="TODOS">Todos os tipos</option>
              {Object.entries(transactionLabels).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div className="transaction-summary__table-container">
            <table className="transaction-summary__table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tipo</th>
                  <th>Data</th>
                  <th>Valor</th>
                  <th>Tarifa de Custo</th>
                  <th>Tarifa Sell In</th>
                  <th>Tarifa Sell Out</th>
                  <th>Valor Líquido</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((tx) => {
                  const netAmount =
                    tx.amount - tx.costFee - tx.sellInFee - tx.sellOutFee;

                  return (
                    <tr key={tx.id}>
                      <td>{tx.id}</td>
                      <td>{transactionLabels[tx.type]}</td>
                      <td>{formatDate(tx.date)}</td>
                      <td className="align-right">
                        {formatCurrency(tx.amount)}
                      </td>
                      <td className="align-right">
                        {formatCurrency(tx.costFee)}
                      </td>
                      <td className="align-right">
                        {formatCurrency(tx.sellInFee)}
                      </td>
                      <td className="align-right">
                        {formatCurrency(tx.sellOutFee)}
                      </td>
                      <td className="align-right">
                        {formatCurrency(netAmount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="transaction-summary__totals">
                  <td colSpan={3}>Totais</td>
                  <td className="align-right">
                    {formatCurrency(totals.amount)}
                  </td>
                  <td className="align-right">
                    {formatCurrency(totals.costFee)}
                  </td>
                  <td className="align-right">
                    {formatCurrency(totals.sellInFee)}
                  </td>
                  <td className="align-right">
                    {formatCurrency(totals.sellOutFee)}
                  </td>
                  <td className="align-right">
                    {formatCurrency(totals.netAmount)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="transaction-summary__stats">
            <div className="transaction-summary__stat-card">
              <h3>Total de Transações</h3>
              <p>{filteredTransactions.length}</p>
            </div>
            <div className="transaction-summary__stat-card">
              <h3>Valor Total</h3>
              <p>{formatCurrency(totals.amount)}</p>
            </div>
            <div className="transaction-summary__stat-card">
              <h3>Total de Tarifas</h3>
              <p>
                {formatCurrency(
                  totals.costFee + totals.sellInFee + totals.sellOutFee
                )}
              </p>
            </div>
            <div className="transaction-summary__stat-card">
              <h3>Valor Líquido</h3>
              <p>{formatCurrency(totals.netAmount)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalizersPage;
