import { useEffect, useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import 'react-medium-image-zoom/dist/styles.css';
import dayjs from "dayjs";
import './styles.scss'
import { formatCpf } from "../../../utils/format";
import { getProposal } from "../../../services/banking";
import { searchLogTransactions } from "../../../services/log-index";
import { FaAngleDoubleDown, FaAngleDoubleRight } from "react-icons/fa";
import JSONInput from "react-json-editor-ajrm";
import * as locale from 'react-json-editor-ajrm/locale/pt';

const ProposalPage = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [proposal, setProposal] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [proposalLogTransactions, setProposalLogTransactions] = useState<any>([]);
  const [displayLogDetails, setDisplayLogDetails] = useState<any>([]);

  useEffect(() => {
    const q = new URLSearchParams(location.search)
    const proposal_id = Number(q.get("id"));
    if (proposal_id) {
      fetchProposal(proposal_id);
    }
  }, [location.search]);

  const fetchProposal = async (proposal_id: number) => {
    setLoading(true);
    try {
      const res = await getProposal(proposal_id);
      setProposal(res.data[0])

      //fetch log transactions if account exists
      if (res && res.data) {
        console.log("🚀 ~ file: index.tsx ~ line 41 ~ fetchProposal ~ res.data", res.data)
        const transactions = await searchLogTransactions(res.data[0].transaction_key)
        if (transactions.data)
          setProposalLogTransactions(transactions.data)
      }
    } catch (error) {
      console.error(error);
      window.alert(error);
    }
    setLoading(false);
  }

  const toggleDisplayLog = (i: number) => {
    debugger
    if (!displayLogDetails[i])
      displayLogDetails[i] = false
    const newLog = [...displayLogDetails];
    newLog[i] = !!!displayLogDetails[i];
    setDisplayLogDetails(newLog);
  }

  if (loading) {
    return <div className="text-center mt-5">Carregando...</div>
  }

  if (proposal) {
    return (
      <div className="container">
        <div className="my-4">
          <button className="action-button" onClick={() => navigate("/insurance-proposals")}> &#8592; &nbsp;{"Voltar"}</button>
        </div>
        <div className="card my-4 p-4">
          <div className="row">
            <div className="col-12">
              <h3 className="" style={{ backgroundColor: "transparent" }}>Dados da proposta</h3>
              <hr className="my-3" />

              <div className="p-4">
                <div className="mb-3">
                  <b>Nome</b>
                  <div>{proposal.customer.nome || '-'}</div>
                </div>
                <div className="mb-3">
                  <b>CPF</b>
                  <div>{formatCpf(proposal.customer.cpf) || '-'}</div>
                </div>
                <div className="mb-3">
                  <b>Data de Nascimento</b>
                  <div>{dayjs(proposal.customer.dtNasc).format('DD/MM/YYYY') || '-'}</div>
                </div>
                {/* <div className="mb-3">
                  <b>Celular</b>
                  <div>{((proposal.customer.phones && proposal.customer.phones[0]) && proposal.customer.phones[0].phone) || '-'}</div>
                </div> */}
                <div className="mb-3">
                  <b>Método de pagamento</b>
                  <div>{proposal.method_payment || '-'}</div>
                </div>
                <div className="mb-3">
                  <b>Status</b>
                  <div> {(proposal.status === "completed") ? "completa" :
                    (proposal.status === "up_to_date") ? "em dia" :
                      (proposal.status === "awaiting_registration" ? "aguardando registro" :
                        (proposal.status === "awaiting_payment") ? "aguardando pagamento" :
                          (proposal.status === "not_effective") ? "não efetivada" :
                            (proposal.status === "overdue") ? "pagamento em atraso" :
                              (proposal.status === "suspended") ? "suspensa" :
                                (proposal.status === "canceled" ? "cancelada" : ""))}</div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h3 style={{ backgroundColor: "transparent" }}>Parcelas</h3>
              <hr className="my-3" />
              <div className="p-4">

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Número</th>
                      <th scope="col">Método pagamento</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Valor pago</th>
                      <th scope="col">Multa</th>
                      <th scope="col">Data vencimento</th>
                      <th scope="col">Data pagamento</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposal.installments?.reverse().map((installment: any) =>
                      <tr
                        className="interactive-table-row"
                        key={installment.id}
                      >
                        <th scope="row">{installment.installment_number}</th>
                        <td>{installment.type}</td>
                        <td>{installment.base_value || "-"}</td>
                        <td>{installment.paid_value || "-"}</td>
                        <td>{installment.fine || "-"}</td>
                        <td>{dayjs(installment.due_date).format("DD/MM/YYYY HH:mm")}</td>
                        <td>{installment.payment_datetime ? dayjs(installment.payment_datetime).format("DD/MM/YYYY HH:mm") : '-'}</td>
                        <td>{(installment.status === "open") ? "aberta" :
                          (proposal.status === "paid") ? "paga" :
                            (proposal.status === "canceled" ? "cancelada" :
                              (proposal.status === "manual_payment" ? "pagamento manual" :
                                (proposal.status === "substituted") ? "substituída" : ""))}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <h2>Log de Transações</h2>
          <hr className="my-3" />

          <div>
            <table className="table table-striped mb-4">
              <thead>
                <tr>
                  <th className="table-column-title" scope="col">
                    Operação
                  </th>
                  <th>Sucesso</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {proposalLogTransactions && proposalLogTransactions.length > 0 && (
                  <>
                    {proposalLogTransactions.map((txData: any, i: number) => (
                      <>
                        <tr className="interactive-table-row"
                          onClick={() => toggleDisplayLog(i)}>
                          <td>
                            {!displayLogDetails[i] && <FaAngleDoubleDown></FaAngleDoubleDown>}
                            {displayLogDetails[i] && <FaAngleDoubleRight></FaAngleDoubleRight>}
                            <span className="pl-2">{txData.req?.uri}</span></td>
                          <td>
                            <button type="button" className={`btn btn-sm ${txData.res?.sucesso ? 'btn-success' : 'btn-danger'}`}>{(txData.res?.sucesso) ? 'SUCESSO' : 'ERRO'}</button>
                          </td>
                          <td>{dayjs(txData.date).format("DD/MM/YYYY HH:mm")}</td>
                        </tr>
                        {displayLogDetails[i] && (<tr><td style={{ columnSpan: "all" }}><JSONInput
                          id='preview_json'
                          locale={locale}
                          placeholder={txData}
                          height='500px'
                          width="100%"
                          viewOnly={true}
                        /></td></tr>)}

                      </>
                    ))}
                  </>)}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  } else {
    return <div className="text-center mt-5">Proposta não encontrada</div>
  }
}



export default ProposalPage;