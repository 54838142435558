import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formattedCurrency, getTransfer } from "../../../services/banking";
import entradaDinheiro from "../../../assets/images/entrada_dinheiro.svg";
import saidaDinheiro from "../../../assets/images/saida_dinheiro.svg";
import "./styles.scss";
import dayjs from "dayjs";
import * as locale from "react-json-editor-ajrm/locale/pt";
import { FaAngleDoubleDown, FaAngleDoubleRight } from "react-icons/fa";
import JSONInput from "react-json-editor-ajrm";

const TransferPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [transfer, setTransfer] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [displayLogDetails, setDisplayLogDetails] = useState(false);

	const toggleDisplayLog = () => {
		setDisplayLogDetails(!displayLogDetails);
	};

	useEffect(() => {
		const q = new URLSearchParams(location.search);
		const transfer_id = Number(q.get("id"));
		if (transfer_id) {
			fetchTransfer(transfer_id);
		}
	}, [location.search]);

	const fetchTransfer = async (transfer_id: number) => {
		setLoading(true);
		try {
			const res = await getTransfer(transfer_id);

			//fetch log transactions if account exists
			if (res && res.data) setTransfer(res.data);
			console.log(
				"🚀 ~ file: index.tsx ~ line 33 ~ fetchTransfer ~ res.data",
				res.data
			);
			console.log(transfer);
		} catch (error) {
			console.error(error);
			window.alert(error);
		}
		setLoading(false);
	};

	if (loading) {
		return <div className="text-center mt-5">Carregando...</div>;
	}

	if (transfer) {
		return (
			<div className="transfer-container">
				<div className="back-btn">
					<button
						className="action-button"
						onClick={() => navigate("/schedules-transfer")}
					>
						{" "}
						&#8592; &nbsp;{"Voltar"}
					</button>
				</div>
				<div className="panel-content">
					<h1 className="transfer-receipt-title">Detalhes da Transferência</h1>

					<div className="payment-receipt">
						<span className="transfer-span-title-18">Agendado para</span>
						<span className="row-span-title-18 w700">
							{" " + dayjs(transfer.scheduled_date).format(`DD/MM/YYYY`)}
						</span>
					</div>

					<div className="transfer-value">
						<h2 className="info-span-bold-16">Valor</h2>
						<span className="info-span-grey-16">
							{formattedCurrency(Number(transfer.vlr_transacao))}
						</span>
					</div>
					<hr />
					<div className="transfer-receipt-message">
						<div className="info-div">
							<h1 className="info-span-bold-16">Mensagem:</h1>
							<h1 className="info-span-grey-16">{transfer.desc_cliente}</h1>
						</div>
					</div>

					<div className="transfer-receipt-img-div">
						<img src={entradaDinheiro} alt="" />
						<span className="info-span-bold-16">Destinatário</span>
					</div>

					<div className="transfer-info-div">
						<div className="info-div">
							<h1 className="info-span-bold-16"> Conta</h1>
							<h1 className="info-span-grey-16">
								{transfer.destino_conta_digito
									? transfer.destino_conta + "-" + transfer.destino_conta_digito
									: transfer.destino_conta}
							</h1>
						</div>

						<div className="info-div">
							<h1 className="info-span-bold-16">Agencia</h1>
							<h1 className="info-span-grey-16">
								{transfer.destino_agencia_digito
									? transfer.destino_agencia +
									  "-" +
									  transfer.destino_agencia_digito
									: transfer.destino_agencia}
							</h1>
						</div>
					</div>

					<div className="transfer-receipt-img-div">
						<img src={saidaDinheiro} alt="" />
						<span className="info-span-bold-16">Origem</span>
					</div>

					<div className="transfer-info-div">
						<div className="info-div">
							<h1 className="info-span-bold-16">Nome</h1>
							<h1 className="info-span-grey-16">{transfer.customer_pf.nome}</h1>
						</div>

						<div className="info-div">
							<h1 className="info-span-bold-16">CPF</h1>
							<h1 className="info-span-grey-16">{transfer.customer_pf.cpf}</h1>
						</div>

						<div className="info-div">
							<h1 className="info-span-bold-16"> Conta</h1>
							<h1 className="info-span-grey-16">
								{transfer.origem_conta_digito
									? transfer.origem_conta + "-" + transfer.origem_conta_digito
									: transfer.origem_conta}
							</h1>
						</div>

						<div className="info-div">
							<h1 className="info-span-bold-16">Agencia</h1>
							<h1 className="info-span-grey-16">
								{transfer.origem_agencia_digito
									? transfer.origem_agencia +
									  "-" +
									  transfer.origem_agencia_digito
									: transfer.origem_agencia}
							</h1>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="buttonRow">
						<h2>Log de Transações</h2>
					</div>
					<hr className="my-3" />

					<div>
						<table className="table table-striped mb-4">
							<thead>
								<tr>
									<th className="table-column-title" scope="col">
										Operação
									</th>
									<th>Status</th>
									<th>Data</th>
								</tr>
							</thead>
							<tbody>
								<tr
									className="interactive-table-row"
									onClick={() => toggleDisplayLog()}
								>
									<td>
										{!!displayLogDetails && (
											<FaAngleDoubleDown></FaAngleDoubleDown>
										)}
										{!displayLogDetails && (
											<FaAngleDoubleRight></FaAngleDoubleRight>
										)}
										<span className="pl-2">Transferência</span>
									</td>
									<td>
										<button
											type="button"
											className={`btn btn-sm ${
												transfer.status === "completed"
													? "btn-success"
													: transfer.status === "pending"
													? "btn-warning"
													: "btn-danger"
											}`}
										>
											{transfer.status}
										</button>
									</td>
									<td>{transfer.scheduled_date}</td>
								</tr>
								{!!displayLogDetails && (
									<td style={{ columnSpan: "all" }}>
										<JSONInput
											id="preview_json"
											locale={locale}
											placeholder={transfer}
											height="500px"
											width="100%"
											viewOnly={true}
										/>
									</td>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	} else {
		return <div className="text-center mt-5">Transferência não encontrada</div>;
	}
};

export default TransferPage;
