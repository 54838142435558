import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchSchedulesTed } from "../../../services/banking";
import { ScheduleTed } from "../../../typings/ScheduleTed";
import { formatCpf } from "../../../utils/format";
import "./styles.scss";

const SchedulesTedPage = () => {
	const navigate = useNavigate();
	const [schedules, setSchedules] = useState<ScheduleTed[]>([]);
	const [loading, setLoading] = useState(true);

	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		fetchSchedulesTed();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const fetchSchedulesTed = async () => {
		console.log(`fetchSchedulesTed...`);
		setLoading(true);
		try {
			const res = await searchSchedulesTed(page, searchText);
			if (res.data) {
				setSchedules(res.data);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	return (
		<div className="container-fluid">
			<div className="card my-4 p-4">
				<div className="row mb-4">
					<h1 className="mb-4">Agendamentos TED</h1>
					<div className="d-flex" style={{ gap: "20px" }}>
						<div className="search-container">
							<input
								className="search-input"
								type="text"
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								onKeyDown={(e) => e.key === "Enter" && fetchSchedulesTed()}
							/>
						</div>
					</div>
				</div>
				{loading ? (
					<div className="text-center mt-5">Carregando...</div>
				) : (
					<div>
						{schedules && schedules.length > 0 ? (
							<div>
								<table className="table table-striped">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Descrição</th>
											<th scope="col">Agendado para</th>
											<th scope="col">Nome Origem</th>
											<th scope="col">CPF Origem</th>
											<th scope="col">Conta Origem</th>
											<th scope="col">Banco Destino</th>
											<th scope="col">Agência Destino</th>
											<th scope="col">Conta Destino</th>
											<th scope="col">Valor</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										{schedules.map((schedule) => (
											<tr
												className="interactive-table-row"
												onClick={() =>
													navigate("/scheduled-ted?id=" + schedule.id)
												}
												key={schedule.id}
											>
												<th scope="row">{schedule.id}</th>
												<td>{schedule.desc_cliente}</td>
												<td>
													{dayjs(schedule.scheduled_date).format(
														"DD/MM/YYYY HH:mm"
													)}
												</td>
												<td>{schedule.customer_pf.nome}</td>
												<td>
													{formatCpf(schedule.favorecido_documento_federal)}
												</td>
												<td>
													{schedule.origem_conta +
														"-" +
														schedule.origem_conta_digito}
												</td>
												<td>{schedule.favorecido_numero_banco}</td>
												<td>{schedule.favorecido_agencia}</td>
												<td>
													{schedule.favorecido_conta +
														"-" +
														schedule.favorecido_conta_digito}
												</td>
												<td>{schedule.vlr_transacao}</td>
												<td>
													<button
														type="button"
														className={`btn btn-sm ${
															schedule.status === "completed"
																? "btn-success"
																: schedule.status === "not_completed"
																? "btn-danger"
																: "btn-warning"
														}`}
													>
														{schedule.status === "completed"
															? "concluído"
															: schedule.status === "not_completed"
															? "erro"
															: "pendente"}
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="text-center mt-5">
								Não foi encontrado nenhum agendamento
							</div>
						)}
						<div className="text-center px-3 pt-3">
							{page > 1 && (
								<button
									className="page-selector"
									onClick={() => setPage(page - 1)}
								>
									{"<"}
								</button>
							)}
							<label className="page-selector">{page}</label>
							{schedules.length > 19 && (
								<button
									className="page-selector"
									onClick={() => setPage(page + 1)}
								>
									{">"}
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SchedulesTedPage;
