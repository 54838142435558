import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchClientePf, searchClientePj } from "../../../services/banking";
import { ClientePf, ClientePfAprovado } from "../../../typings/ClientePf";
import { formatCnpj, formatCpf } from "../../../utils/format";
import "./styles.scss";
import { ClientePj, ClientePjAprovado } from "../../../typings/ClientePj";

export enum ClientType {
  PF = "PF",
  PJ = "PJ",
}

const Users = () => {
  const navigate = useNavigate();
  const [usersPF, setUsers] = useState<ClientePf[]>([]);
  const [usersPJ, setUsersPJ] = useState<ClientePj[]>([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [approvedFilter, setApprovedFilter] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [clientTypeFilter, setClientTypeFilter] = useState<ClientType>(
    ClientType.PF
  );

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, approvedFilter, clientTypeFilter]);

  const fetchUsers = async () => {
    console.log(`fetchUsers...`);
    setLoading(true);
    if (clientTypeFilter === ClientType.PF) {
      try {
        const res = await searchClientePf(page, approvedFilter, searchText);
        if (res.data) {
          setUsers(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await searchClientePj(page, approvedFilter, searchText);
        if (res.data) {
          setUsersPJ(res.data!);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  const displayAprovadoStatus = (
    aprovado: ClientePfAprovado | ClientePjAprovado
  ) => {
    const aprovadoStatus = {
      0: "Conferência manual",
      1: "Aguardando abertura",
      2: "Erro abertura",
      3: "Aprovada",
      4: "Recusada",
    };
    return aprovadoStatus[aprovado];
  };

  return (
    <div className="container-fluid">
      <div className="card my-4 p-4">
        <div className="row mb-4">
          <h1 className="mb-4">Fila de Abertura</h1>
          <div className="d-flex" style={{ gap: "20px" }}>
            <div className="search-container">
              <select
                value={clientTypeFilter}
                className="filter-select"
                onChange={(e) => {
                  const v = e.target.value;
                  setClientTypeFilter(v as ClientType);
                }}
              >
                <option value={"PF"}>PF</option>
                <option value={"PJ"}>PJ</option>
              </select>
            </div>
            <div className="search-container align-items-center">
              <select
                value={approvedFilter}
                className="filter-select"
                onChange={(e) => {
                  const v = e.target.value;
                  setApprovedFilter(v);
                }}
              >
                <option value={"all"}>Todos</option>
                <option value={"0"}>Conferência manual</option>
                <option value={"1"}>Aguardando abertura</option>
                <option value={"2"}>Erro abertura</option>
                <option value={"3"}>Aprovada</option>
                <option value={"4"}>Recusada</option>
              </select>
            </div>
            <div className="search-container">
              <input
                className="search-input"
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && fetchUsers()}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="text-center mt-5">Carregando...</div>
        ) : (
          <div>
            {(usersPF && usersPF.length > 0) ||
            (usersPJ && usersPJ.length > 0) ? (
              <div>
                <table className="table table-striped">
                  {clientTypeFilter === ClientType.PF ? (
                    <>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Nome</th>
                          <th scope="col">CPF</th>
                          <th scope="col">Status</th>
                          <th scope="col">Criação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {usersPF.map((user) => (
                          <tr
                            className="interactive-table-row"
                            onClick={() =>
                              navigate("/user?id=" + user.id + "&type=PF")
                            }
                            key={user.id}
                          >
                            <th scope="row">{user.id}</th>
                            <td>{user.nome}</td>
                            <td>{formatCpf(user.cpf)}</td>
                            <td>{displayAprovadoStatus(user.aprovado)}</td>
                            <td>
                              {dayjs(user.createdAt).format("DD/MM/YYYY HH:mm")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Nome Fantasia</th>
                          <th scope="col">Razão Social</th>
                          <th scope="col">CNPJ</th>
                          <th scope="col">CPF</th>
                          <th scope="col">Status</th>
                          <th scope="col">Criação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {usersPJ.map((user) => (
                          <tr
                            className="interactive-table-row"
                            onClick={() =>
                              navigate("/user?id=" + user.id + "&type=PJ")
                            }
                            key={user.id}
                          >
                            <th scope="row">{user.id}</th>
                            <td>{user.nomeFantasia}</td>
                            <td>{user.razaoSocial}</td>
                            <td>{formatCnpj(user.cnpj)}</td>
                            <td>{formatCpf(user.cpfResponsavelEmpresa)}</td>
                            <td>{displayAprovadoStatus(user.aprovado!)}</td>
                            <td>
                              {dayjs(user.createdAt).format("DD/MM/YYYY HH:mm")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
            ) : (
              <div className="text-center mt-5">
                Não foi encontrado nenhum usuário
              </div>
            )}
            <div className="text-center px-3 pt-3">
              {page > 1 && (
                <button
                  className="page-selector"
                  onClick={() => setPage(page - 1)}
                >
                  {"<"}
                </button>
              )}
              <label className="page-selector">{page}</label>
              {usersPF.length > 19 && (
                <button
                  className="page-selector"
                  onClick={() => setPage(page + 1)}
                >
                  {">"}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
