import { Link, NavLink } from "react-router-dom";
import "./styles.scss";
import logo from "../../../assets/images/kikkin_white_logo.svg";
import { ReactElement, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { formatCpf } from "../../../utils/format";
import {
  FaUserAlt,
  FaKey,
  FaCalendar,
  FaClinicMedical,
  FaMoneyBill,
} from "react-icons/fa";

const Navbar = ({ children }: { children: ReactElement }) => {
  const { user, logout } = useContext(AuthContext);

  return (
    <div className="main-wrapper">
      <div className="header-wrapper">
        <div className="header container-fluid">
          <div className="d-flex align-items-center">
            <img className="nav-logo" src={logo} alt="logo" />
          </div>
          <div className="d-flex align-items-center">
            <label className="px-3">{user?.name}</label>
            <label>|</label>
            <label className="px-3">{formatCpf(user?.cpf)}</label>
            <Link to="#" className="nav-link" onClick={logout}>
              Sair
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="nav">
          <ul className="nav-bar">
            <div>
              <li className="nav-bar-item sub-menu">
                <NavLink to={"/users"} className="nav-item">
                  <FaUserAlt size={32} />
                  <span className="text-green">Abertura de conta</span>
                </NavLink>
              </li>
              <li className="nav-bar-item">
                <NavLink to={"/password"} className="nav-item">
                  <FaKey size={24} />
                  <span className="text-green">Redefinição de senha</span>
                </NavLink>
              </li>
              <li className="nav-bar-item sub-menu">
                <span className="nav-item">
                  <FaCalendar size={24} />
                  <span className="text-green">Agendamentos</span>
                </span>
                <ul className="nav-sub-menu">
                  <li>
                    <NavLink
                      to={"/schedules-boleto"}
                      className="nav-item sub-align-left"
                    >
                      <span className="text-green">Agendamentos de Boleto</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/schedules-pix"}
                      className="nav-item sub-align-left"
                    >
                      <span className="text-green">Agendamentos de Pix</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/schedules-transfer"}
                      className="nav-item sub-align-left"
                    >
                      <span className="text-green">
                        Agendamentos Transferência
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/schedules-ted"}
                      className="nav-item sub-align-left"
                    >
                      <span className="text-green">Agendamentos TED</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-bar-item">
                <NavLink to={"/insurance-proposals"} className="nav-item">
                  <FaClinicMedical size={24} />
                  <span className="text-green">Seguro</span>
                </NavLink>
              </li>
              <li className="nav-bar-item sub-menu">
                <span className="nav-item">
                  <FaMoneyBill size={24} />
                  <span className="text-green">Tarifação</span>
                </span>
                <ul className="nav-sub-menu">
                  <li>
                    <NavLink
                      to={"/transaction-fees"}
                      className="nav-item sub-align-left"
                    >
                      <span className="text-green">Gerenciar Tarifas</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/totalizers"}
                      className="nav-item sub-align-left"
                    >
                      <span className="text-green">Totalizadores</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </div>
          </ul>
        </div>
        <div className="content-wrapper">{children}</div>
      </div>
    </div>
  );
};

export default Navbar;
