import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchSchedulesTransfer } from "../../../services/banking";
import { ScheduleTransfer } from "../../../typings/ScheduleTransfer";
import { formatCnpj, formatCpf } from "../../../utils/format";
import "./styles.scss";

const SchedulesTransferPage = () => {
	const navigate = useNavigate();
	const [schedules, setSchedules] = useState<ScheduleTransfer[]>([]);
	const [loading, setLoading] = useState(true);

	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		fetchSchedulesTransfer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const fetchSchedulesTransfer = async () => {
		console.log(`fetchSchedulesTransfer...`);
		setLoading(true);
		try {
			const res = await searchSchedulesTransfer(page, searchText);
			console.log(
				"🚀 ~ file: index.tsx ~ line 29 ~ fetchSchedulesTransfer ~ res",
				res
			);
			if (res.data) {
				setSchedules(res.data);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	return (
		<div className="container-fluid">
			<div className="card my-4 p-4">
				<div className="row mb-4">
					<h1 className="mb-4">Agendamentos de Transferência</h1>
					<div className="d-flex" style={{ gap: "20px" }}>
						<div className="search-container">
							<input
								className="search-input"
								type="text"
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								onKeyDown={(e) => e.key === "Enter" && fetchSchedulesTransfer()}
							/>
						</div>
					</div>
				</div>
				{loading ? (
					<div className="text-center mt-5">Carregando...</div>
				) : (
					<div>
						{schedules && schedules.length > 0 ? (
							<div>
								<table className="table table-striped">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Descrição</th>
											<th scope="col">Agendado para</th>
											<th scope="col">Nome / Empresa Origem</th>
											<th scope="col">CPF / CNPJ Origem</th>
											<th scope="col">Conta Origem</th>
											<th scope="col">Conta Destino</th>
											<th scope="col">Valor</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										{schedules.map((schedule) => (
											<tr
												className="interactive-table-row"
												onClick={() =>
													navigate("/scheduled-transfer?id=" + schedule.id)
												}
												key={schedule.id}
											>
												<th scope="row">{schedule.id}</th>
												<td>{schedule.desc_cliente}</td>
												<td>
													{dayjs(schedule.scheduled_date).format(
														"DD/MM/YYYY HH:mm"
													)}
												</td>
												<td>{schedule.customer_pf && schedule.customer_pf.nome || schedule.customer_pj && schedule.customer_pj.razaoSocial}</td>
												<td>{schedule.customer_pf && formatCpf(schedule.customer_pf?.cpf) || schedule.customer_pj && formatCnpj(schedule.customer_pj.cnpj)}</td>
												<td>
													{schedule.origem_conta +
														"-" +
														schedule.origem_conta_digito}
												</td>
												<td>
													{schedule.destino_conta +
														"-" +
														schedule.destino_conta_digito}
												</td>
												<td>{schedule.vlr_transacao}</td>
												<td>
													<button
														type="button"
														className={`btn btn-sm ${
															schedule.status === "completed"
																? "btn-success"
																: schedule.status === "not_completed"
																? "btn-danger"
																: "btn-warning"
														}`}
													>
														{schedule.status}
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="text-center mt-5">
								Não foi encontrado nenhum agendamento
							</div>
						)}
						<div className="text-center px-3 pt-3">
							{page > 1 && (
								<button
									className="page-selector"
									onClick={() => setPage(page - 1)}
								>
									{"<"}
								</button>
							)}
							<label className="page-selector">{page}</label>
							{schedules.length > 19 && (
								<button
									className="page-selector"
									onClick={() => setPage(page + 1)}
								>
									{">"}
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SchedulesTransferPage;
