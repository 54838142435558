import dayjs from "dayjs";
import { FormEvent, useState } from "react"
import ReactInputMask from "react-input-mask";
import { getClientePfByCpf, sendResetPassEmail } from "../../../services/banking";
import { ClientePf } from "../../../typings/ClientePf";
import { formatCpf } from "../../../utils/format";
import { validateCpf } from "../../../utils/validation";

const PasswordReset = () => {

    const [clientePf, setClientePf] = useState<ClientePf>();
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const searchUser = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true);
        try {
            if (!validateCpf(searchText)) throw new Error("CPF inválido");
            const res = await getClientePfByCpf(searchText);
            if (res.data && res.data.cpf) {
                setEmailSent(false);
                setClientePf(res.data);
            } else {
                throw new Error("Cliente não encontrado");
            }
        } catch (error) {
            window.alert(error);
        }
        setLoading(false);
    }

    const sendEmail = async () => {
        setLoading(true);
        try {
            if (clientePf?.cpf) {
                await sendResetPassEmail(clientePf?.cpf);
                setEmailSent(true);
            }
        } catch (error) {
            window.alert(error);
        }
        setLoading(false);
    }

    return (
        <div className="container">
            <div className="card my-4 p-4">
                <h1>Redefinir senhas</h1>
                <p>
                    Busque um cliente por CPF e solicite o envio do email de redefinição de senha
                </p>

                <form className="search-container" onSubmit={searchUser}>
                    <ReactInputMask
                        mask="999.999.999-99" id="cpf-input"
                        className={"search-input"}
                        placeholder="Digite o CPF"
                        disabled={loading}
                        value={searchText} onChange={e => setSearchText(e.target.value)}
                    />
                </form>
                {loading ?
                    <p className="text-center my-5">Carregando...</p>
                    :
                    (clientePf && <div className="mt-5">
                        <h2>Dados do Cliente</h2>
                        <div className="row gy-4">
                            <div className="col-4">
                                <b>Nome</b>
                                <div>{clientePf.nome}</div>
                            </div>
                            <div className="col-4">
                                <b>CPF</b>
                                <div>{formatCpf(clientePf.cpf)}</div>
                            </div>
                            <div className="col-4">
                                <b>Data de Nascimento</b>
                                <div>{dayjs(clientePf.dtNasc).format('DD/MM/YYYY')}</div>
                            </div>
                            <div className="col-4">
                                <b>Celular</b>
                                <div>{(clientePf.phones && clientePf.phones[0]) && clientePf.phones[0].phone}</div>
                            </div>
                            <div className="col-4">
                                <b>Apelido</b>
                                <div>{clientePf.apelido}</div>
                            </div>
                            <div className="col-4">
                                <b>Nome da mãe</b>
                                <div>{clientePf.nomeMae}</div>
                            </div>
                        </div>
                        <div className="text-center mt-3" >
                            <button className="action-button" onClick={() => sendEmail()} disabled={emailSent}>
                                {emailSent ?
                                    'E-mail de redefinição enviado!' :
                                    'Enviar e-mail de redefinição de senha'
                                }
                            </button>
                        </div>
                    </div>)
                }

            </div>
        </div>
    )
}

export default PasswordReset