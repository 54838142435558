import { Address } from "./ClientePf";

export enum ClientePjStatus {
  INATIVO = 0, // INATIVO
  ATIVO = 1, // COMPLETO - Conta aberta, senha criada, acesso ok.
  ONBOARDING = 2, // Usuario em onboarding (preenchendo telas do onboarding)
  PARTIAL = 3, // Usuario inicia o onboarding em outro canal, por exemplo seguro...
  CREATE_DIGITAL_SIGNATURE_PENDING = 4, // Onboarding completo, já possui conta criada, mas não cadastrou assinatura digital
  FIRST_LOGIN = 5, // Onboarding completo, já possui conta, mas não fez o primeiro login
}

export enum ClientePjAprovado {
  WAITING_MANUAL_APPROVAL, // AGUARDANDO VALIDACAO MANUAL
  WAITING_ACCOUNT_OPEN, // AGUARDANDO ABERTURA DE CONTA AUTOMATICA
  ERROR_ACCOUNT_OPEN, // ERROR ABERTURA DE CONTA
  APPROVED, // APROVADO, tudo ok.
  REFUSED, // RECUSADO
}

export type ClientePj = {
  id: number;
  nomeFantasia: string;
  razaoSocial: string;
  documentoEstadual: string;
  documentoMunicipal: string;
  nomeResponsavelEmpresa: string;
  cpfResponsavelEmpresa: string;
  dtNascimentoResponsavel?: Date;
  cnpj: string;
  status: ClientePjStatus;
  addresses: Address[];
  documents: any;
  emails: any;
  phones: any;
  cards?: any;
  accounts: any;
  operadores: any;
  boleto_schedules: any;
  pix_schedules: any;
  transfer_schedules: any;
  ted_schedules: any;
  favoritos: any;
  oidc_id?: string;
  dtJobAccountOpen?: Date;
  aprovado: ClientePjAprovado;
  srp_salt?: string;
  srp_verifier?: string;
  srp_serialized_state?: string;
  srp_session_secret?: string;
  createdAt: Date;
  modifiedAt?: Date;
};
