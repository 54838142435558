import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getOperatorByOidcId, updateUserAccountOperador } from "../../../../services/banking";
import { OperadorPj } from "../../../../typings/OperadorPj";
import "./styles.scss";

const EditUserOperadorPj = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<OperadorPj | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const q = new URLSearchParams(location.search);
    const oidc_id = String(q.get("oidc_id"));
    if (oidc_id) {
      fetchUser(oidc_id);
    }
  }, [location.search]);

  const fetchUser = async (oidc_id: string) => {
    try {
      const response = await getOperatorByOidcId(oidc_id);
      setUser(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!user) {
        console.error("User is null. Cannot save.");
        return;
      }

      const userPayload = {
        oidc_id: user.oidc_id || "",
        email: user.emails?.[0]?.email || "",
        phone: user.phones?.[0]?.phone || "",
        nome: user.nome,
      };

      await updateUserAccountOperador(userPayload.oidc_id, userPayload);

      setLoading(false);
      navigate("/users");
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  if (!user) {
    return <div className="text-center mt-5">Carregando...</div>;
  }

  if (loading) {
		return <div className="text-center mt-5">Carregando...</div>;
	}

  return (
    <div className="container">
      <div className="card p-4">
        <h3 className="my-4">Editar Operador PJ</h3>
        <div className="mb-3">
          <label>Nome</label>
          <input
            type="text"
            className="form-control"
            value={user.nome}
            onChange={(e) =>
              setUser((prevUser) => ({ ...prevUser!, nome: e.target.value }))
            }
          />
        </div>
        <div className="mb-3">
          <label>CPF</label>
          <input
            type="text"
            className="form-control"
            value={user.cpf}
            onChange={(e) =>
              setUser((prevUser) => ({ ...prevUser!, cpf: e.target.value }))
            }
            disabled
          />
        </div>
        <div className="mb-3">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            value={user.emails?.[0]?.email || ""}
            onChange={(e) =>
              setUser((prevUser) => ({
                ...prevUser!,
                emails: [
                  {
                    id: prevUser?.emails?.[0]?.id || 0,
                    email: e.target.value,
                    emailType: prevUser?.emails?.[0]?.emailType || 1,
                  },
                ],
              }))
            }
          />
        </div>
        <div className="mb-3">
          <label>Telefone</label>
          <input
            type="text"
            className="form-control"
            value={user.phones?.[0]?.phone || ""}
            onChange={(e) =>
              setUser((prevUser) => ({
                ...prevUser!,
                phones: [
                  {
                    id: prevUser?.phones?.[0]?.id || 0,
                    phone: e.target.value,
                    phoneType: prevUser?.phones?.[0]?.phoneType || 1,
                    status: prevUser?.phones?.[0]?.status || 1,
                  },
                ],
              }))
            }
          />
        </div>
        <div className="container-buttons-actions">
          <button className="action-button" onClick={handleSave}>
            Salvar
          </button>
          <button
            className="action-button mx-3"
            onClick={() => navigate("/users")}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditUserOperadorPj;
