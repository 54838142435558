import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
//import { FieldValues, useForm } from "react-hook-form";
//import { useNavigate } from "react-router-dom";
//import ReactInputMask from "react-input-mask";
//import { validateCpf } from "../../../utils/validation";
import "./styles.scss"

const LoginPage = () => {

    const { login, loading } = useContext(AuthContext);

    //const { register, handleSubmit, formState: { errors } } = useForm();


    /* useEffect(() => {
        if(user) navigate("/")
    }, [user, navigate]) */

    /* const handleLogin = async (data: FieldValues) => {
        login(data.cpf, data.password);
    } */

    return (
        <div className="gray-bg" >
            <div className="corner-image-bg">
                <div className="login-container">
                    <h1 className="mb-4">Login</h1>
                    {/* <ReactInputMask 
                        mask="999.999.999-99" id="cpf-input"
                        {...register("cpf", { required: true, validate: validateCpf, pattern: /^\d\d\d\.\d\d\d\.\d\d\d-\d\d$/ })}  
                        className={errors?.cpf ? "k4n-input input-invalid" : "k4n-input"} 
                        placeholder="Digite seu CPF" 
                    />
                    <input 
                        {...register("password", { required: true, minLength: 3 })}
                        type="password" placeholder={"Digite sua Senha"} className="k4n-input" />
                    <Button onClick={handleSubmit(handleLogin)} /> */}

                    <button 
                        className={"start-button" + (loading ? ` loading` : ``)} 
                        onClick={login} 
                        disabled={loading}
                    >
                        Autenticar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;