import { createContext, ReactElement, ReactNode, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { getConsoleUserByOidcId } from "../services/banking";
import { KeycloakTokenParsed } from "keycloak-js";

type UserType = {
 cpf: string
 name: string
}

type AuthContextType = {
  user: UserType | null,
  loading: boolean,
  login: () => void,
  logout: () => void,
};

const contextDefaultValues: AuthContextType = {
  user: null,
  loading: false,
  login: () => {},
  logout: () => {}
};

export const AuthContext = createContext<AuthContextType>(contextDefaultValues);

const AuthProvider = ({ children }: {children: ReactNode}): ReactElement => {
  
  const { initialized, keycloak } = useKeycloak();

  const [user, setUser] = useState<UserType | null>(contextDefaultValues.user);
  const [loading, setLoading] = useState(contextDefaultValues.loading);

  useEffect(() => {
    console.log("AuthContext")
    if(initialized) {
      console.log(keycloak)
      if (keycloak.idTokenParsed && keycloak.authenticated) {
        handleSuccessLogin(keycloak.idTokenParsed)
      }
    }
  }, [initialized, keycloak])

  const login = () => {
    keycloak.login()
  }

  const logout = () => {
    setUser(null);
    keycloak.logout()
  }

  const handleSuccessLogin = async (user: KeycloakTokenParsed) => {
    setLoading(true);
    try {
      if(user && user.sub) {
        console.log(user);
        const u = await getConsoleUserByOidcId(user.sub);
        setUser(u.data);
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.message || error
      window.alert(message);
    }
    setLoading(false);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
