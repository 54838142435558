import './global.scss'
import AuthProvider from './context/AuthContext';
import RootRoutes from './routes';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { kc } from './services/keycloak';

const App = () => {
  return (
    <ReactKeycloakProvider authClient={kc} >
      <AuthProvider>
        <RootRoutes />
      </AuthProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
