import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getClientePfById } from "../../../../services/banking";
import { ClientePf } from "../../../../typings/ClientePf";
import { Address } from "../../../../typings/Address";
import { getClientePfDocuments } from "../../../../services/ocr";
import { updateAddress, updateUserAccount } from "../../../../services/banking";
import { uploadDocumentos } from "../../../../services/ocr";
import "./styles.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { DocumentType } from "../../../../typings/DocumentType";
import ZipCodeService from "../../../../services/zipcode";

const EditUserClientPf = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<ClientePf | null>(null);
  const [loadingCep, setLoadingCep] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const q = new URLSearchParams(location.search);
    const user_id = Number(q.get("id"));
    if (user_id) {
      fetchUser(user_id);
    }
  }, [location.search]);

  const fetchUser = async (user_id: number) => {
    try {
      const response = await getClientePfById(user_id);
      setUser(response.data);

      const documentsResponse = await getClientePfDocuments(user_id);
      setDocuments(documentsResponse.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCepChange = async (cep: string) => {
    setLoadingCep(true);
    try {
      const res = await ZipCodeService.consultZipCode(cep.replace("-", ""));
      if (res.data.erro) {
        window.alert("CEP inválido. Por favor, verifique.");
      } else {
        setUser((prevUser) => {
          if (!prevUser) return prevUser;
          const updatedAddresses = [
            {
              ...prevUser.addresses?.[0],
              cep,
              logradouro: res.data.logradouro || "",
              bairro: res.data.bairro || "",
              cidade: res.data.localidade || "",
              uf: res.data.uf || "",
              numero: prevUser.addresses?.[0]?.numero || "",
              complemento: prevUser.addresses?.[0]?.complemento || "",
              addressType: prevUser.addresses?.[0]?.addressType || "",
              status: prevUser.addresses?.[0]?.status || 0,
              idempotencyKey: prevUser.addresses?.[0]?.idempotencyKey || "",
            } as Address,
          ];
          return { ...prevUser, addresses: updatedAddresses };
        });
      }
    } catch (error) {
      console.error(error);
      window.alert("Erro ao buscar informações do CEP.");
    }
    setLoadingCep(false);
  };

  const handleAddressChange = (field: keyof Address, value: string) => {
    setUser((prevUser) => {
      if (!prevUser) return prevUser;
      const updatedAddresses = [
        {
          ...prevUser.addresses?.[0],
          [field]: value || "",
        } as Address,
      ];
      return { ...prevUser, addresses: updatedAddresses };
    });
  };

  const handleDocumentUpload = (index: number, file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setDocuments((prevDocs) => {
        const updatedDocs = [...prevDocs];
        updatedDocs[index] = {
          ...updatedDocs[index],
          uploadedUri: reader.result as string,
          file,
        };
        return updatedDocs;
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!user) {
        console.error("User is null. Cannot save.");
        return;
      }

      if (user.addresses) {
        await updateAddress(user.addresses[0], user.cpf);
      }

      const userPayload = {
        oidc_id: user.oidc_id || "",
        email: user.emails?.[0]?.email || "",
        phone: user.phones?.[0]?.phone || "",
        nome: user.nome,
      };

      await updateUserAccount(userPayload.oidc_id, userPayload);

      for (const doc of documents) {
        if (doc.file) {
          try {
            const response = await uploadDocumentos(
              doc.uploadedUri,
              user.id,
              doc.doc.documentType,
              doc.doc.idempotencyKey
            );
            console.log("Document upload response:", response.data);
          } catch (error) {
            console.error("Error uploading document:", error);
          }
        }
      }

      setLoading(true);
      navigate("/users");
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getDocumentTypeTitle = (documentType: DocumentType) => {
    console.log("DocumentType", documentType);
    switch (documentType) {
      case DocumentType.CPF:
        return "Documento: CPF";
      case DocumentType.RG:
        return "Documento: RG";
      case DocumentType.PASSPORT:
        return "Documento: Passaporte";
      case DocumentType.PROVA_DE_VIDA:
        return "Documento: Prova de Vida";
      case DocumentType.CNH:
        return "Documento: CNH";
      case DocumentType.COMPROVANTE_RESIDENCIA:
        return "Documento: Comprovante de Residência";
      case DocumentType.CONTRATO_SOCIAL:
        return "Documento: Contrato Social";
      case DocumentType.BILHETE_SEGURO:
        return "Documento: Bilhete de Seguro";
      case DocumentType.TERMO_CONTRATACAO_SEGURO:
        return "Documento: Termo de Contratação de Seguro";
      default:
        return "Documento: Outro";
    }
  };

  if (!user) {
    return <div className="text-center mt-5">Carregando...</div>;
  }

  if (loading) {
		return <div className="text-center mt-5">Carregando...</div>;
	}

  return (
    <div className="container">
      <div className="card p-4">
        <h3 className="my-4">Editar Cliente PF</h3>
        <div className="mb-3">
          <label>Nome</label>
          <input
            type="text"
            className="form-control"
            value={user.nome}
            onChange={(e) =>
              setUser((prevUser) => ({ ...prevUser!, nome: e.target.value }))
            }
          />
        </div>
        <div className="mb-3">
          <label>Celular</label>
          <input
            type="text"
            className="form-control"
            value={user.phones?.[0]?.phone || ""}
            onChange={(e) =>
              setUser((prevUser) => ({
                ...prevUser!,
                phones: [{ ...prevUser?.phones?.[0], phone: e.target.value }],
              }))
            }
          />
        </div>
        <div className="mb-3">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            value={user.emails?.[0]?.email || ""}
            onChange={(e) =>
              setUser((prevUser) => ({
                ...prevUser!,
                emails: [{ ...prevUser?.emails?.[0], email: e.target.value }],
              }))
            }
          />
        </div>
        <h3 className="mt-4">Endereço</h3>
        <div className="mb-3">
          <label>CEP</label>
          <input
            type="text"
            className="form-control"
            value={user.addresses?.[0]?.cep || ""}
            onChange={(e) => handleAddressChange("cep", e.target.value)}
            onBlur={(e) => handleCepChange(e.target.value)}
          />
          {loadingCep && (
            <div className="text-muted">Buscando informações...</div>
          )}
        </div>
        <div className="mb-3">
          <label>Logradouro</label>
          <input
            type="text"
            className="form-control"
            value={user.addresses?.[0]?.logradouro || ""}
            onChange={(e) => handleAddressChange("logradouro", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Bairro</label>
          <input
            type="text"
            className="form-control"
            value={user.addresses?.[0]?.bairro || ""}
            onChange={(e) => handleAddressChange("bairro", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Cidade</label>
          <input
            type="text"
            className="form-control"
            value={user.addresses?.[0]?.cidade || ""}
            onChange={(e) => handleAddressChange("cidade", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>UF</label>
          <input
            type="text"
            className="form-control"
            value={user.addresses?.[0]?.uf || ""}
            onChange={(e) => handleAddressChange("uf", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Número</label>
          <input
            type="text"
            className="form-control"
            value={user.addresses?.[0]?.numero || ""}
            onChange={(e) => handleAddressChange("numero", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Complemento</label>
          <input
            type="text"
            className="form-control"
            value={user.addresses?.[0]?.complemento || ""}
            onChange={(e) => handleAddressChange("complemento", e.target.value)}
          />
        </div>
        <h3 className="mt-4">Documentos</h3>
        {documents.map((doc, index) => (
          <div key={index} className="mb-4">
            <h5>{getDocumentTypeTitle(doc.doc.documentType)}</h5>
            <div className="row">
              <div className="col-6">
                {doc.uploadedUri || doc.awsUrl ? (
                  doc.mimetype === "application/pdf" ? (
                    <embed
                      src={doc.uploadedUri || doc.awsUrl}
                      width="100%"
                      height="200px"
                      type="application/pdf"
                    />
                  ) : (
                    <Zoom>
                      <img
                        src={doc.uploadedUri || doc.awsUrl}
                        alt={`Documento ${index + 1}`}
                        width="100%"
                        style={{ cursor: "zoom-in" }}
                      />
                    </Zoom>
                  )
                ) : (
                  <div className="text-muted">Documento não disponível</div>
                )}
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label>Substituir Documento</label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*,application/pdf"
                    onChange={(e) =>
                      e.target.files &&
                      handleDocumentUpload(index, e.target.files[0])
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="container-buttons-actions">
          <button className="action-button" onClick={handleSave}>
            Salvar
          </button>
          <button
            className="action-button mx-3"
            onClick={() => navigate("/users")}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditUserClientPf;
