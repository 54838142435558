import axios from "axios";

let h: any = {}
if (process.env.REACT_APP_PARTNER_ID)
    h['x-k4n-tenant'] = `${process.env.REACT_APP_PARTNER_ID}`;

if (process.env.REACT_APP_API_TOKEN)
    h['Authorization'] = `${process.env.REACT_APP_API_TOKEN}`;

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: h
});

export default api;