export enum ClientePfAprovado {
	WAITING_MANUAL_APPROVAL = 0, // AGUARDANDO VALIDACAO MANUAL
	WAITING_ACCOUNT_OPEN = 1, // AGUARDANDO ABERTURA DE CONTA AUTOMATICA
	ERROR_ACCOUNT_OPEN = 2, // ERROR ABERTURA DE CONTA
	APPROVED = 3, // APROVADO, tudo ok.
	REFUSED = 4, // RECUSADO
}

export type Address = {
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	uf: string;
	addressType: string;
	status: number;
	idempotencyKey?: string;
};

export type ClientePf = {
	id: number;
	nome: string;
	cpf: string;
	apelido: string;
	dtNasc: string;
	nomeMae: string;
	status: number;
	aprovado: ClientePfAprovado;
	createdAt: string;
	phones?: any;
	addresses?: Address[];
	emails?: any[];
	accounts?: any[];
};
